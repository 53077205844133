import React from 'react';

import EmailUnavailableErrorMessage from './EmailUnavailableErrorMessage';

export const CONSTRAINT_MESSAGES = {
  INVALID: 'does not appear to be a valid',
  INVALID_SIZE: 'needs at least one',
  REQUIRED_FIELD: 'required',
  TOO_LONG: 'Your response is too long (maximum is %{count} characters)',
  // eslint-disable-next-line react/jsx-filename-extension
  EMAIL_UNAVAILABLE: <EmailUnavailableErrorMessage />,
  EMAIL_INVALID: 'Please enter a valid email address.'
};

export const CONSTRAINT_TYPES = {
  EMAIL: 'email',
  EMAIL_VALID: 'emailValid',
  NUMBER: 'number',
  PASSWORD: 'password',
  PRICE: 'price',
  TELEPHONE: 'telephone',
  REQUIRED_TELEPHONE: 'requiredTelephone',
  TEXT: 'text',
  NON_EMPTY: 'nonEmpty',
  ZIP: 'zip',
  REQUIRED_ZIP: 'requiredZip',
  BOOLEAN: 'boolean',
  URL: 'url',
  FACEBOOK_URL: 'facebookUrl',
  INSTAGRAM_URL: 'instagramUrl',
  INSTAGRAM_HANDLE: 'instagramHandle',
  TERMS: 'terms',
  NON_PO_ADDRESS: 'nonPOAddress',
  BREEDER_PROGRAM_NAME: 'breederProgramName',
  PUPPY_NAME_FORMAT_CONSTRAINT: 'puppyNameFormat'
};

export const LARGEST_INT = 2147483647;

export const EMAIL_CONSTRAINTS = {
  format: {
    pattern: /[\s]*[\S]+@[\S]+\.[\S]+[\s]*/,
    message: '^Please enter a valid email address.'
  }
};

export const EMAIL_VALID_CONSTRAINT = {
  emailValid: true
};

export const EMAIL_VALIDATION_TYPES = {
  EXISTS: 'exists',
  INVALID: 'invalid'
};

export const NUMBER_CONSTRAINTS = {
  truthy: true,
  numericality: {
    strict: true,
    notGreaterThan: LARGEST_INT,
    message: `${CONSTRAINT_MESSAGES.INVALID} number.`
  }
};

export const PASSWORD_CONSTRAINTS = {
  truthy: true,
  length: {
    minimum: 6,
    message: '^Passwords must be at least 6 characters.'
  }
};

// where SimpleCurrencyInput with type="number" specified is used,
// a price such as 1. or 123. will not cause invalidation
// this is because . does not fire the onChange
export const PRICE_CONSTRAINTS = {
  truthy: true,
  format: {
    pattern: /^\d+(?:.\d{1,2})?$/,
    message: `${CONSTRAINT_MESSAGES.INVALID} amount.`
  }
};

export const TELEPHONE_CONSTRAINTS = {
  format: {
    pattern: /^\d{10}$/,
    message: '^Please enter a valid 10-digit phone number.'
  }
};

export const REQUIRED_TELEPHONE_CONSTRAINTS = {
  ...TELEPHONE_CONSTRAINTS,
  nonEmpty: true
};

export const TEXT_CONSTRAINTS = {
  truthy: true
};

export const ZIP_CONSTRAINTS = {
  format: {
    pattern: /\d{5}(-\d{4})?/,
    message: `^Please enter a valid 5-digit zip code.`
  }
};

export const REQUIRED_ZIP_CONSTRAINTS = {
  ...ZIP_CONSTRAINTS,
  nonEmpty: true
};

export const BOOLEAN_CONSTRAINT = {
  boolean: true
};

export const TERMS_CONSTRAINT = {
  truthy: true,
  boolean: true
};

export const FACEBOOK_URL_REGEX = /facebook\.com\/.+/i;

export const FACEBOOK_URL_CONSTRAINT = {
  optionalFormat: {
    pattern: FACEBOOK_URL_REGEX,
    message: 'Enter a valid URL'
  }
};

export const INSTAGRAM_URL_REGEX = /instagram\.com\/.+/i;

export const INSTAGRAM_URL_CONSTRAINT = {
  optionalFormat: {
    pattern: INSTAGRAM_URL_REGEX,
    message: 'Enter a valid URL'
  }
};

export const INSTAGRAM_HANDLE_REGEX = /^[a-z0-9._]{1,30}$/i;

export const INSTAGRAM_HANDLE_CONSTRAINT = {
  optionalFormat: {
    pattern: INSTAGRAM_HANDLE_REGEX,
    message: `${CONSTRAINT_MESSAGES.INVALID} Instagram handle.`
  }
};

export const URL_CONSTRAINT = {
  url: true
};

export const NON_EMPTY_CONSTRAINT = {
  nonEmpty: true
};

export const PO_ADDRESS_REGEX = /\b(?:p\.?\s*o\.?)(\s+)?(?:box|[0-9]*)?\b/i;
export const NON_PO_ADDRESS_CONSTRAINT = {
  inverseFormat: {
    pattern: PO_ADDRESS_REGEX,
    message:
      'Address cannot be a PO box. Please enter a different address or contact help@gooddog.com if you need assistance.'
  }
};

export const BREEDER_PROGRAM_NAME_REGEX = /.*\D.*/i;
export const BREEDER_PROGRAM_NAME_CONSTRAINT = {
  optionalFormat: {
    pattern: BREEDER_PROGRAM_NAME_REGEX,
    message: 'Program name cannot contain numbers only.'
  }
};
export const PUPPY_NAME_FORMAT_CONSTRAINT = {
  format: {
    pattern: /^([a-zA-Z0-9 À-ÖØ-öø-ÿ .,:()'-]+)$/,
    message: `Can only include these special characters: . , : ( ) - '`
  }
};

export default {
  nonEmpty: NON_EMPTY_CONSTRAINT,
  email: EMAIL_CONSTRAINTS,
  emailValid: EMAIL_VALID_CONSTRAINT,
  number: NUMBER_CONSTRAINTS,
  password: PASSWORD_CONSTRAINTS,
  price: PRICE_CONSTRAINTS,
  telephone: TELEPHONE_CONSTRAINTS,
  requiredTelephone: REQUIRED_TELEPHONE_CONSTRAINTS,
  text: TEXT_CONSTRAINTS,
  zip: ZIP_CONSTRAINTS,
  requiredZip: REQUIRED_ZIP_CONSTRAINTS,
  terms: TERMS_CONSTRAINT,
  boolean: BOOLEAN_CONSTRAINT,
  url: URL_CONSTRAINT,
  facebookUrl: FACEBOOK_URL_CONSTRAINT,
  instagramUrl: INSTAGRAM_URL_CONSTRAINT,
  nonPOAddress: NON_PO_ADDRESS_CONSTRAINT,
  breederProgramName: BREEDER_PROGRAM_NAME_CONSTRAINT,
  puppyNameFormat: PUPPY_NAME_FORMAT_CONSTRAINT
};
